import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("../views/auth/Auth.vue"),
  },
  {
    path: "/home",
    name: "Home",
    meta: { requiredAuth: true },
    component: () => import("../views/home/Home.vue"),
  },
  {
    path: "/feed",
    name: "Feed",
    meta: { requiredAuth: true },
    component: () => import("../views/feed/Feed.vue"),
  },
  {
    path: "/users",
    name: "Users",
    meta: { requiredAuth: true },
    component: () => import("../views/users/Users.vue"),
  },
  {
    path: "/user/:id",
    meta: { requiredAuth: true },
    component: () => import("../views/user/User.vue"),
    children: [
      {
        path: "",
        name: "User Profile",
        component: () => import("../views/user/children/Profile.vue"),
      },
      {
        path: "contracts",
        name: "User Contracts",
        component: () => import("../views/user/children/Contracts.vue"),
      },
      {
        path: "reports",
        name: "User Reports",
        component: () => import("../views/user/children/Reports.vue"),
      },
      {
        path: "commissioning",
        name: "User Commissioning",
        component: () => import("../views/user/children/Commissioning.vue"),
      },
    ],
  },
  {
    path: "/products",
    meta: { requiredAuth: true },
    component: () => import("../views/products/Products.vue"),
    children: [
      {
        path: "",
        name: "Products",
        meta: { requiredAuth: true },
        component: () => import("../views/products/children/Products.vue"),
      },
      {
        path: "details/:id",
        name: "Product Details",
        meta: { requiredAuth: true },
        component: () => import("../views/products/children/Details.vue"),
      },
      {
        path: "categories",
        name: "Product Categories",
        meta: { requiredAuth: true },
        component: () => import("../views/products/children/Categories.vue"),
      },
    ],
  },
  {
    path: "/hired-products",
    name: "HiredProducts",
    meta: { requiredAuth: true },
    component: () => import("../views/hiredProducts/HiredProducts.vue"),
  },
  {
    path: "/deposits",
    name: "Deposits",
    meta: { requiredAuth: true },
    component: () => import("../views/deposits/Deposits.vue"),
  },
  {
    path: "/installments",
    name: "Faturas",
    meta: { requiredAuth: true },
    component: () => import("../views/installments/Installments.vue"),
  },
  {
    path: "/withdrawals",
    name: "Withdrawals",
    meta: { requiredAuth: true },
    component: () => import("../views/withdrawals/Withdrawals.vue"),
  },
  {
    path: "/commissions",
    name: "Commissions",
    meta: { requiredAuth: true },
    component: () => import("../views/commissions/Commissions.vue"),
  },
  {
    path: "/system-logs",
    name: "System Logs",
    meta: { requiredAuth: true },
    component: () => import("../views/systemLogs/SystemLogs.vue"),
  },
  {
    path: "/reports",
    name: "Reports",
    meta: { requiredAuth: true },
    component: () => import("../views/reports/Reports.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    meta: { requiredAuth: true },
    component: () => import("../views/settings/Settings.vue"),
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
