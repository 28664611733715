<template>
  <v-container class="background" fill-height align-center justify-center fluid>
    <HollowDotsSpinner  :animation-duration="2000" :size="64" color="#469CEF" />
  </v-container>
</template>

<script>
import { HollowDotsSpinner  } from "epic-spinners";

export default {
  components: {
    HollowDotsSpinner ,
  },
};
</script>

<style></style>
