<template>
  <div class="d-flex flex-column align-center justify-center">
    <HollowDotsSpinner  :animation-duration="2000" :size="32" color="#469CEF" />
  </div>
</template>

<script>
import { HollowDotsSpinner  } from "epic-spinners";

export default {
  components: {
    HollowDotsSpinner ,
  },
};
</script>

<style></style>
