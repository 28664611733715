import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,

    options: {
      customProperties: true,
    },

    themes: {
      light: {
        primary: "#469CEF",
        primary2: "#091242",
        secondary: "#006BB4",
        info: "#2196F3",
        success: "#4CAF50",
        error: "#FF5252",
        warning: "#FFC107",
        background: "#FFFFFF",
        foreground: "#F7F7F7",
        accent: "#F9F9F9",
        surface: "#FAFAFA",
        overtext: "#3F3F44",
        subtext: "#8A8A8E",
      },
      dark: {
        primary: "#469CEF",
        primary2: "#091242",
        secondary: "#006BB4",
        info: "#2196F3",
        success: "#4CAF50",
        error: "#FF5252",
        warning: "#FFC107",
        background: "#0e0e0f",
        foreground: "#131314",
        surface: "#1a1a1c",
        accent: "#1e1e21",
        overtext: "#FFFFFF",
        subtext: "#9b9ca2",
      },
    },
  },

  lang: {
    locales: { pt },
    current: "pt",
  },
});
